import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="container">
          <Header></Header>
          <section className="main">
            <div className="tagline"><h1>&nbsp;</h1>
            <a href="/pdf/537977_DE_o.b._Multilingual_Leaflet_v2.pdf" target="_blank" id="btn-tampon-usage-guide" className="btn btn-secondary btn-tampon-usage" role="button">tampon usage guide</a></div>
            <div className="d-flex flex-row">
              <div className="right">
                <h2 className="country_deduction your_location_title">&nbsp;</h2>
                <ul className="country_deduction country_deduction_list">
                  &nbsp;
                </ul>
                <h6 className="select_country_headline">&nbsp;</h6>
                <h6 className="select_country_title">&nbsp;</h6>
                <div class="scroll-country bottom-faded">
                  <ul className="country_list"></ul>
                </div>
              </div>
              <div className="address hidden" style={{ right: "-500px" }}>
                <div className="left">
                  <a href="#" id="slide-left" className="d-flex">
                  <img src="/images/germany.png" /><span>Germany</span>
                  </a>
                </div>
                <div className="dropdown-language">
                  <button type="button" className="btn btn-primary dropdown-toggle btn-small"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Select Language</button>
                  <div className="dropdown-menu dropdown-menu-right">
                    &nbsp;
                  </div>
                </div>
                <h3 className="address_title">&nbsp;</h3>
                <h6 className="address_details">&nbsp;</h6>
                <a className="btn btn-primary contact_link" href="#" target="_blank" role="button">&nbsp;</a>
                <a className="btn btn-secondary website_link" href="#" target="_blank" role="button">&nbsp;</a>

              </div>

            </div>


          </section>
        </div>
        <Footer></Footer>
        <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js"
          integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut"
          crossOrigin="anonymous"
        />
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js"
          integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k"
          crossOrigin="anonymous"
        />
        <script src="/js/scripts.js"></script>
        <script src="/js/mobile-slide.js"></script>
      </div>
    );
  }
}

export default Page;
